function isMobileNavOpen() {
    return document.body.classList.contains("mobile-nav-open");
}

function setMobileNavState(flag) {
    if (flag) {
        document.body.classList.add("mobile-nav-open");
        $(".navigation-wrap").slideDown("fast");
    } else {
        document.body.classList.remove("mobile-nav-open");
        $(".navigation-wrap").slideUp("fast");
    }
}

$(() => {
    // Disable the usege of link on megamenu parent on click
    $("ul.desktop-menu li.level-0.is-megamenu > a").on("click", (e) => {
        e.preventDefault();
    });

    $(".mobile-nav-toggle-btn").each(function() {
        $(this).on("click", function(e) {
            e.preventDefault();
            $(this).parent().toggleClass("open");
            $(this).siblings("ul").slideToggle("fast");
        });
    });

    // Toggle the visibility of mobile navigation when the menu icon is clicked
    $("#mobile-nav-toggler").click((e) => {
        e.stopPropagation();
        setMobileNavState(!isMobileNavOpen());
    });
});
