// <!--

if (! window.rsConf) { window.rsConf = {}; }
if (! window.rsConf.ui) { window.rsConf.ui = {}; }
window.rsConf.ui.rsbtnClass = 'rsbtn_colorskin';
window.rsConf.ui.player = [
    '<span class="rsbtn_box">',
    '   <a href="javascript:void(0);" class="rsbtn_pause rsimg rspart rsbutton" title="Pause">',
    '       <span class="rsbtn_btnlabel">Pause</span>',
    '   </a>',
    '   <a href="javascript:void(0);" class="rsbtn_stop rsimg rspart rsbutton" title="Stop">',
    '       <span class="rsbtn_btnlabel">Stop</span>',
    '   </a>',
    '   <span class="rsbtn_progress_container rspart">',
    '       <span class="rsbtn_progress_played"></span>',
    '   </span>',
    '   <a href="javascript:void(0);" class="rsbtn_volume rsimg rspart rsbutton" title="Volume">',
    '       <span class="rsbtn_btnlabel">Volume</span>',
    '   </a>',
    '   <span class="rsbtn_volume_container">',
    '       <span class="rsbtn_volume_slider"></span>',
    '   </span>',
    '   <a href="javascript:void(0);" class="rsbtn_dl rsimg rspart rsbutton" title="Download mp3">',
    '       <span class="rsbtn_btnlabel">Download mp3</span>',
    '   </a>',
    '   <a href="javascript:void(0);" class="rsbtn_settings rsimg rspart rsbutton" title="Settings">',
    '       <span class="rsbtn_btnlabel">Settings</span>',
    '   </a>',
    '   <a href="javascript:void(0);" class="rsbtn_closer rsimg rspart rsbutton" title="Close player">',
    '       <span class="rsbtn_btnlabel">Close</span>',
    '   </a>',
    '   <span class="rsdefloat"></span>',
    '   <span class="rsbtn_powered rsimg"><span class="rsbtn_btnlabel">Speech-enabled by ReadSpeaker</span></span>',
    '</span>'
];


window.rsConf.ui.popupbutton = [
  '<a class="rsbtn_play" accesskey="L" title="Listen with ReadSpeaker" href="">',
    '<span class="rsbtn_left rspart"><span class="rsbtn_text"><span>Listen</span></span></span>',
    '<span class="rsbtn_right rsimg rsplay rspart"></span>',
  '</a>'
];
/*
window.rsConf.ui.popupbutton = [];
window.rsConf.ui.popupplayer = [];
*/
// -->

